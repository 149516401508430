import React, { useEffect } from 'react';
import PersonalInformationForm from './intra-page-items/personal-information.item';
import { PersonalInformation } from 'types/personal-information';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import ProfileLayout from 'components/layouts/profile/profile.layout';
import { accountProfileSelector } from 'state/account/account.selectors';
import { useDispatch, useSelector } from 'react-redux';
import {
    accountFetchProfileRoutine,
    accountGetAcknowledgementRoutine,
    accountUpdateProfileRoutine
} from 'state/account/account.routines';
import produce from 'immer';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import UpdateProfileModalContent, {
    FailureUpdateProfileModalContent
} from './intra-page-items/profile-update-modal.item';

const PersonalInfo = () => {
    const { t } = useTranslation();
    const profileObject = useSelector(accountProfileSelector);
    const dispatch = useDispatch();

    const handleSubmit = (personalInformation: PersonalInformation) => {
        if (profileObject) {
            const updatedProfilePayload = produce(profileObject, (draftProfileObject) => {
                draftProfileObject.patientEmailAddress = personalInformation.email;
                const phone = parsePhoneNumberFromString(personalInformation.phoneNumber, 'US');
                if (phone) {
                    draftProfileObject.phones[0].phoneAreaCode = phone.nationalNumber.substr(0, 3);
                    draftProfileObject.phones[0].phoneNumber = phone.nationalNumber.substr(3);
                }
            });
            dispatch(
                accountUpdateProfileRoutine({
                    ...updatedProfilePayload,
                    onSuccess: () => {
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: (
                                    <UpdateProfileModalContent area={t('modals.updateProfile.areas.profile')} />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.updateProfile.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        }
                                    }
                                ]
                            })
                        );
                    },
                    onFailure: () => {
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: (
                                    <FailureUpdateProfileModalContent area={t('modals.updateProfile.areas.profile')} />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.updateProfile.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        }
                                    }
                                ]
                            })
                        );
                    }
                })
            );
        }
    };

    useEffect(() => {
        if (profileObject === undefined) {
            dispatch(accountFetchProfileRoutine.trigger());
        } else {
            dispatch(
                accountGetAcknowledgementRoutine.trigger({
                    patientId: profileObject?.epostPatientNum
                })
            );
        }
    }, [profileObject?.epostPatientNum]);

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.eyebrowText`)}
            title={t(`pages.profile.personalInformation.title`)}
            heading={t(`pages.profile.personalInformation.heading`)}
        >
            <PersonalInformationForm
                defaultValue={{
                    firstName: profileObject?.patientFirstName ? profileObject?.patientFirstName : '',
                    lastName: profileObject?.patientLastName ? profileObject?.patientLastName : '',
                    email: profileObject?.patientEmailAddress ? profileObject?.patientEmailAddress : '',
                    phoneNumber: profileObject?.phones
                        ? `1${profileObject?.phones[0].phoneAreaCode}${profileObject?.phones[0].phoneNumber}`
                        : ''
                }}
                onSubmit={handleSubmit}
            />
        </ProfileLayout>
    );
};

export default PersonalInfo;
