import React, { ReactElement } from 'react';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

const UpdateProfileModalContent = ({ area }: { area: string }): ReactElement => {
    const { t } = useTranslation();
    return (
        <BirdiModalContent
            icon={'success'}
            title={t('modals.updateProfile.title')}
            body={t('modals.updateProfile.body', {
                area
            })}
        />
    );
};

export const FailureUpdateProfileModalContent = ({ area }: { area: string }): ReactElement => {
    const { t } = useTranslation();
    return (
        <BirdiModalContent
            icon={'alert'}
            title={t('modals.updateProfile.error')}
            body={t('modals.updateProfile.failure', {
                area
            })}
        />
    );
};

export default UpdateProfileModalContent;
